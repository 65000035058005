import React, { useEffect } from "react"
import Chevron from "@svgs/chevron.svg";
import { useForm } from "react-hook-form"
import { usePostalJp } from 'use-postal-jp'

// データのエンコード処理
function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const Form = props => {
    const { address, setPostalCode } = usePostalJp()
    const { register, watch, formState: { errors }, formState, handleSubmit } = useForm({
        mode: 'onChange'
    });
    const { onClickConfirm, isConfirmation, onClickBack, onClickSubmit } = props;
    const watchEverything = watch();
    const watchAddressText = watch("addressText", ((address.prefecture && address.address1 && address.address2) && address.prefecture + address.address1 + address.address2));
    console.log(watchEverything)
    useEffect(() => {
        setPostalCode(watchEverything.postalCode1 + watchEverything.postalCode2)
    }, [watchEverything.postalCode1, watchEverything.postalCode2, setPostalCode])
    const onSubmit = (data) => {
        //　　データの送信処理、form-nameをformのnameと合わせる。
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...data })
        })
            .then(() => {
                console.log(encode({ "form-name": "contact", ...data }))
                onClickSubmit()
            })
            .catch((error) => {
                // 失敗時の処理
            });
    };

    return (
        <form
            name="contact"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" name="form-name" value="contact" />

            <table className="border w-full">
                <tr>
                    {!isConfirmation &&
                        <div className="form-group">
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">会社名
                                    <div title="required" className="h-8 bg-primary text-white px-4">必須</div>
                                </th>
                            </div>
                            <td className="flex flex-col justify-center px-4 py-3 bg-white text-black lg:table-cell">
                                <label>
                                    <input type="text" placeholder="例）木原興業株式会社" {...register("companyName", { required: true, max: 20, min: 2, maxLength: 20 })} className="flex-row m-1 px-4 py-2 h-12 tracking-wider outline-none border-collapse leading-4 form-control bg-blockquotebg text-black block lg:table-cell w-full" />
                                </label>
                                <div className="flex flex-row text-red-400">{errors.companyName && '会社名は2文字以上、20文字以下でなければなりません。（必須項目）'}</div>
                            </td>
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">メールアドレス
                                    <div title="required" className="h-8 bg-primary text-white px-4">必須</div>
                                </th>
                            </div>
                            <td className="flex flex-col justify-center px-4 py-3 bg-white text-black lg:table-cell">
                                <label>
                                    <input type="text" placeholder="例）abc@kiharak.co.jp" {...register("email", {
                                        required: true, max: 40, maxLength: 40,
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    })} className="flex-row m-1 px-4 py-2 h-12 tracking-wider outline-none border-collapse leading-4 form-control bg-blockquotebg text-black block lg:table-cell w-full" />
                                </label>
                                <div className="flex flex-row text-red-400">{errors.email && 'メールアドレスを入力してください。（必須項目）'}</div>
                            </td>
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">電話番号
                                </th>
                            </div>
                            <td className="flex flex-col justify-center px-4 py-3 bg-white text-black lg:table-cell">
                                <label>
                                    <input type="tel" placeholder="例）086-000-0000" {...register("mobileNumber", {
                                        required: false, maxLength: 14,
                                        pattern: /^0\d{2,3}-\d{1,4}-\d{4}$/
                                    })} className="flex-row m-1 px-4 py-2 h-12 tracking-wider outline-none border-collapse leading-4 form-control bg-blockquotebg text-black block lg:table-cell w-full" />
                                </label>
                                <div className="flex flex-row text-red-400">{errors.mobileNumber && '電話番号（ハイフンあり）を入力してください。'}</div>
                            </td>
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">住所
                                </th>
                            </div>
                            <td className="flex justify-center px-4 py-3 bg-white text-black lg:table-cell">
                                <div className="flex flex-col justify-between  px-4 py-3">
                                    <div className="flex flex-row items-center">
                                        <div className="text-2xl">〒</div>
                                        <input type="text" {...register("postalCode1", {
                                            required: false, maxLength: 3,
                                            pattern: /^\d{3}$/
                                        })} className="m-1 px-4 py-2 h-12 tracking-wider outline-none border-collapse leading-4 form-control bg-blockquotebg text-black block lg:table-cell w-1/4" />
                                        <div className="text-2xl">ー</div>
                                        <input type="text" {...register("postalCode2", {
                                            required: false, maxLength: 4,
                                            pattern: /^\d{4}$/
                                        })} className="m-1 px-4 py-2 h-12 tracking-wider outline-none border-collapse leading-4 form-control bg-blockquotebg text-black block lg:table-cell w-1/4" />
                                    </div>
                                    <div className="flex flex-row">
                                        <input type="text" {...register("addressText", { required: false, max: 40, min: 5, maxLength: 50 })} defaultValue={((address.prefecture && address.address1 && address.address2) && address.prefecture + address.address1 + address.address2)} className="m-1 px-4 py-2 h-12 tracking-wider outline-none border-collapse leading-4 form-control bg-blockquotebg text-black block lg:table-cell w-full"></input>
                                    </div>
                                    <div className="flex flex-row text-red-400">{(errors.postalCode1 || errors.postalCode2) && '郵便番号を入力してください。'}</div>
                                </div>
                            </td>
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">お問い合わせ内容
                                    <div title="required" className="h-8 bg-primary text-white px-4">必須</div>
                                </th>
                            </div>
                            <td className="flex flex-col justify-center px-4 py-3 bg-white text-black lg:table-cell">
                                <label>
                                    <textarea placeholder="例）電磁接触器の納期をしりたい" {...register("content", { required: true, max: 200, min: 10, maxLength: 200 })} className="m-1 px-4 py-2 h-32 tracking-wider outline-none border-collapse leading-6 form-control bg-blockquotebg text-black block lg:table-cell w-full" />
                                </label>
                                <div className="flex flex-row text-red-400">{errors.content && 'お問い合わせ内容は10文字以上、200字以下でなければなりません。（必須項目）'}</div>
                            </td>
                            <div className="text-1xl flex justify-start px-4 h-14 items-center">
                                <input className="m-4 transform scale-150" type="checkbox" placeholder="confirm" {...register("confirm", { required: true })} />個人情報取扱いについて同意する
                            </div>
                        </div>
                    }
                    {isConfirmation &&
                        <div className="form-group w-96">
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">会社名
                                </th>
                            </div>
                            <label>
                                <td className="flex justify-start px-4 py-3 bg-white text-black lg:table-cell">
                                    {watchEverything.companyName}
                                </td>
                            </label>
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">メールアドレス
                                </th>
                            </div>
                            <label>
                                <td className="flex justify-start px-4 py-3 bg-white text-black lg:table-cell">
                                    {watchEverything.email}
                                </td>
                            </label>
                            {watchEverything.mobileNumber &&
                                <>
                                    <div className="bg-secondary">
                                        <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">電話番号
                                        </th>
                                    </div>
                                    <label>
                                        <td className="flex justify-start px-4 py-3 bg-white text-black lg:table-cell">
                                            {watchEverything.mobileNumber}
                                        </td>
                                    </label>
                                </>
                            }
                            {(watchEverything.postalCode1 && watchEverything.postalCode2 && watchAddressText) &&
                                <>
                                    <div className="bg-secondary">
                                        <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">住所
                                        </th>
                                    </div>
                                    <td className="flex justify-start items-center px-4 py-3 bg-white text-black lg:table-cell">
                                        <div className="flex flex-col justify-between  px-4 py-3">
                                            <label>
                                                <div className="flex flex-row items-center">
                                                    <div className="text-2xl">〒</div>
                                                    {watchEverything.postalCode1}
                                                    <div className="text-2xl">ー</div>
                                                    {watchEverything.postalCode2}
                                                </div>
                                                <div className="flex flex-row">
                                                    {watchAddressText}
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                </>
                            }
                            <div className="bg-secondary">
                                <th className="flex justify-between h-14 text-left px-4 py-3 text-lg text-primary font-bold">お問い合わせ内容
                                </th>
                            </div>
                            <label>
                                <td className="flex justify-start px-4 py-3 bg-white text-black lg:table-cell whitespace-pre-line">
                                    {watchEverything.content}
                                </td>
                            </label>
                        </div>
                    }
                </tr>
            </table>
            {!isConfirmation &&
                <div className="flex justify-between form-group">
                    <span></span>
                    <button disabled={!formState.isValid} className={!formState.isValid ? 'inline-flex justify-between items-center rounded-xl w-1/3 m-2 h-10 text-lg bg-gray-400 text-white' : 'inline-flex justify-between items-center rounded-xl w-1/3 m-2 h-10 text-lg bg-primary text-white'} onClick={onClickConfirm}>
                        <span ></span>
                        <span >確認する</span>
                        <Chevron
                            className="w-3 my-2 fill-current text-white"
                        />
                    </button>
                    <span></span>
                </div>
            }
            {isConfirmation &&
                <div className="form-group inline-flex w-full">
                    <button className="inline-flex justify-between items-center rounded-xl w-full m-2 h-10 text-lg bg-primary text-white" onClick={onClickBack}>
                        <Chevron
                            className="w-3 my-3 transform rotate-180 fill-current text-white"
                        />
                        <span>戻る</span>
                        <span ></span>
                    </button>
                    <button type="submit" className="inline-flex justify-between items-center rounded-xl w-full m-2 h-10 text-lg bg-primary text-white">
                        <span ></span>
                        <span >送信する</span>
                        <Chevron
                            className="w-3 my-2 fill-current text-white"
                        />
                    </button>
                </div>
            }
        </form>
    )

}

export default Form