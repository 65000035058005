import React, { useState } from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import Form from "@components/form"

const Contact = () => {
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const onClickConfirm = () => {
    setIsConfirmation(true)
    window.scrollTo(0, 0);
  }
  const onClickBack = () => {
    setIsConfirmation(false)
    window.scrollTo(0, 0);
  }
  const onClickSubmit = () => {
    setIsSubmit(true)
    window.scrollTo(0, 0);
  }
  return (
    <Layout>
      <Meta
        title="問い合わせ"
        desc="XXX"
      />{ }

      <Heading.H1 text="問い合わせ" />
      {!isConfirmation &&
        <div className="px-5 mb-12">
          <p>電機機械・器具のご相談、ご依頼についてのお問い合わせ。また、採用についてなどご質問、ご相談等はこちらのお問い合わせフォームから受け付けております。</p>
        </div>
      }
      {(isConfirmation && !isSubmit) &&
        <div className="px-5 mb-12">
          <p>入力した内容で問題なければ「送信する」ボタンをクリックしてください。内容を修正する場合は「戻る」ボタンをクリックしてください</p>
        </div>
      }
      {isSubmit &&
        <div className="px-5 mb-12">
          <p>お問い合わせをありがとうございます。内容を確認し弊社より折り返しご連絡させていただきます。</p>
        </div>
      }
      <Heading.H4 text="お問い合わせフォーム" />
      {!isSubmit &&
        <div className="flex justify-center w-full">
          <Form onClickConfirm={onClickConfirm} isConfirmation={isConfirmation} onClickBack={onClickBack} onClickSubmit={onClickSubmit} isSubmit={isSubmit} />
        </div>
      }
      {isSubmit &&
        <div className="px-5 mb-12">
          <p>送信しました。</p>
        </div>
      }
      {!isConfirmation &&
        <>
          <Heading.H4 text="電話連絡先" />
          <div className="px-5 mb-12">
            <p>岡山本社：086-225-2291</p>
            <p>大阪支店：06-6262-0971</p>
            <p>今治支店：0898-41-9280</p>
          </div>
        </>
      }
    </Layout>
  )
}

export default Contact;